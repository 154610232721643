import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetch = createAsyncThunk('loretta3/fetch', async (payload) => {
    return [];
})

const initialState = {
    message: null,
    loading: false,
    error: null,
    company:{
        name: 'loretta3',
        whatsapp: '573244302025',
    }
}

const siteSlice = createSlice({
    name: 'loretta3',
    initialState: initialState,
    reducers: {
        init(state, action) {
            state = { ...initialState };
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetch.pending, (state, action) => {
            state.loading = true;
          })
          .addCase(fetch.fulfilled, (state, action) => {
            
            state.loading = false;
          })
          .addCase(fetch.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error?.message;            
          })
    }

})

export const {init} = siteSlice.actions;
export default siteSlice.reducer;