import { CardText, CardTitle, Container } from 'react-bootstrap';
import Banner from './Banner';
import One from './pieces/One';
import logo from '../../content/img/logo.png'
import { List, Card, CardBody, CardHeader, Row, Col } from 'reactstrap';




const Home = () => {
    return <Container>

        <>
            <header>
                <div className="container">
                    {/* <h1>Loretta3</h1>
                    <p>Soluciones IT</p> */}
                    <Row xs={1} md={2}>
                        <Col>
                            <Card>
                                <img src={logo} alt="Loretta3 Soluciones IT" class="logo" />
                                <CardBody>
                                    <h2>¡Soluciones informáticas a tu medida!</h2>
                                </CardBody>

                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardText>


                                </CardText>
                            </Card>
                        </Col>
                    </Row>


                </div>
            </header>

            <section className="cta">
                <div className="container">
                    <p><b>En Loretta3,</b> nos dedicamos a ofrecer soluciones informáticas personalizadas que transforman la operativa empresarial en la era digital. A través de un enfoque basado en tres pilares fundamentales—estrategia, desarrollo e innovación—proporcionamos asesoría profesional para la construcción ágil de software y aplicaciones a medida, optimizando procesos y operaciones específicas de cada negocio. Nuestra experiencia en diversos sectores nos permite entender las necesidades de nuestros clientes y ofrecer soluciones efectivas que no solo mejoran la competitividad, sino que también preparan a las empresas para enfrentar los retos del futuro.

                    </p>
                </div>
            </section>

            <section className="benefits">
                <div className="container">
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader><h3>Consultoría</h3></CardHeader>
                                <CardBody>
                                    <p>Lo que se mide se puede controlar, se puede gestionar y se puede mejorar.  ¡Enfoca tus esfuerzos en lo que realmente importa!.</p>
                                </CardBody>
                                <CardText>
                                    Asesoría estratégica para transformar digitalmente tu negocio y aumentar su competitividad.
                                </CardText>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                <CardHeader><h3>Presencia Digital</h3></CardHeader>
                                <CardBody>
                                    <p>En la era digital, si tu negocio no existe en internet tu negocio tiende a desaparecer.</p>
                                </CardBody>
                                <CardText>
                                    Crea y gestiona la identidad online de tu empresa con sitios web, SEO y redes sociales.
                                </CardText>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                <CardHeader>
                                    <h3>Desarrollo a la medida</h3>
                                </CardHeader>
                                <CardBody>
                                    <p>Construcción de software personalizado de acuerdo con las necesidades de su compañía.</p>
                                </CardBody>
                                <CardText>
                                    Soluciones de software y aplicaciones personalizadas que optimizan tus procesos.
                                </CardText>
                            </Card>
                        </Col>



                    </Row>




                </div>
            </section>

            <section>
                <div className="container">
                    <h2>Contáctanos</h2>
                    <form action="submit_form.php" method="POST">
                        <label for="name">Nombre Completo:</label>
                        <input type="text" id="name" name="name" required />

                        <label for="email">Correo Electrónico:</label>
                        <input type="email" id="email" name="email" required />

                        <label for="company">Empresa (opcional):</label>
                        <input type="text" id="company" name="company" />

                        <label for="message">Mensaje:</label>
                        <textarea id="message" name="message" rows="4" required></textarea>

                        <button type="submit">Enviar</button>
                    </form>
                </div>
            </section>
            <footer>
                <div className="container">
                    <p>&copy; 2024 Loretta3 Soluciones IT. Todos los derechos reservados.</p>
                </div>
            </footer>
        </>
    </Container>
}

export default Home;